import logo from './logo.svg';
import InputForm from './components/InputForm/InputForm';
import ManualSection from './components/Manual/Manual';
import './App.css';

const App = () => {
  return (
    <>
      <header>
        <h1>BuckShot Roulette Supporter</h1>
      </header>
      <main>
        <InputForm />
        <ManualSection />
      </main>
      <footer>
        <p>&copy; 2024 Studio-NoppoSan.</p>
      </footer>
    </>
  );
}

export default App;
