import React, { useState } from "react";
import "./InputForm.css";

const InputForm = () => {
  const [redCount, setRedCount] = useState(0);
  const [blueCount, setBlueCount] = useState(0);
  const [childElements, setChildElements] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleButtonClick = (value, color) => {
    if (color === "red") {
      setRedCount(value);
      setTotalCount(value + blueCount); // トータル数を更新
      const newChildElements = Array(value)
        .fill("red")
        .concat(Array(blueCount).fill("blue"));
      setChildElements(newChildElements);
    } else {
      setBlueCount(value);
      setTotalCount(redCount + value); // トータル数を更新
      const newChildElements = Array(redCount)
        .fill("red")
        .concat(Array(value).fill("blue"));
      setChildElements(newChildElements);
    }
  };

  const handleClick = (index) => {
    const newChildElements = childElements.filter((_, i) => i !== index);
    const newTotalCount = newChildElements.length;
    const newRedCount = newChildElements.filter(
      (color) => color === "red"
    ).length;
    const newBlueCount = newTotalCount - newRedCount;

    setChildElements(newChildElements);
    setTotalCount(newTotalCount);
    setRedCount(newRedCount);
    setBlueCount(newBlueCount);
  };

  const calculateRedProbability = () => {
    if (totalCount === 0) {
      return 0;
    }
    return (redCount / totalCount) * 100;
  };

  const handleReset = () => {
    setRedCount(0);
    setBlueCount(0);
    setTotalCount(0);
    setChildElements([]);
  };

  return (
    <div className="input-form">
      <div className="shell-live">
        <h2>Live Shell Count</h2>
        <div className="shell-buttons">
          <button
            className="btn red zero"
            onClick={() => handleButtonClick(0, "red")}
          >
            0
          </button>
          <button
            className="btn red one"
            onClick={() => handleButtonClick(1, "red")}
          >
            1
          </button>
          <button
            className="btn red two"
            onClick={() => handleButtonClick(2, "red")}
          >
            2
          </button>
          <button
            className="btn red three"
            onClick={() => handleButtonClick(3, "red")}
          >
            3
          </button>
          <button
            className="btn red three"
            onClick={() => handleButtonClick(4, "red")}
          >
            4
          </button>
          <button
            className="btn red one"
            onClick={() => handleButtonClick(5, "red")}
          >
            5
          </button>
          <button
            className="btn red two"
            onClick={() => handleButtonClick(6, "red")}
          >
            6
          </button>
          <button
            className="btn red three"
            onClick={() => handleButtonClick(7, "red")}
          >
            7
          </button>
          <button
            className="btn red three"
            onClick={() => handleButtonClick(8, "red")}
          >
            8
          </button>
        </div>
      </div>
      <div className="shell-empty">
        <h2>Empty Shell Count</h2>
        <div className="shell-buttons">
          <button
            className="btn blue four"
            onClick={() => handleButtonClick(0, "blue")}
          >
            0
          </button>
          <button
            className="btn blue zero"
            onClick={() => handleButtonClick(1, "blue")}
          >
            1
          </button>
          <button
            className="btn blue one"
            onClick={() => handleButtonClick(2, "blue")}
          >
            2
          </button>
          <button
            className="btn blue two"
            onClick={() => handleButtonClick(3, "blue")}
          >
            3
          </button>
          <button
            className="btn blue three"
            onClick={() => handleButtonClick(4, "blue")}
          >
            4
          </button>
          <button
            className="btn blue zero"
            onClick={() => handleButtonClick(5, "blue")}
          >
            5
          </button>
          <button
            className="btn blue one"
            onClick={() => handleButtonClick(6, "blue")}
          >
            6
          </button>
          <button
            className="btn blue two"
            onClick={() => handleButtonClick(7, "blue")}
          >
            7
          </button>
          <button
            className="btn blue three"
            onClick={() => handleButtonClick(8, "blue")}
          >
            8
          </button>
        </div>
      </div>
      <div className="reset">
        <button className="btn" onClick={handleReset}>
          Reset
        </button>
      </div>
      <div className="shells-container">
        <h2>In tube shells</h2>
        <div className="child-container">
          {childElements.map((color, index) => (
            <div
              key={index}
              className={`child-element shell-${color}`}
              onClick={() => handleClick(index)}
            ></div>
          ))}
        </div>
        <p>Next Live Shell % : {calculateRedProbability().toFixed(2)}%</p>
      </div>
    </div>
  );
};

export default InputForm;
