import React, { useState } from "react";
import "./Manual.css";

const ManualSection = () => {

  return (
    <div className="manual-section">
      <h2>Manual</h2>
      <p className="ja">
        Live Shell（実包）Empty
        Shell（空包）それぞれの数のボタンを選択すると、In tube
        shellsにその数分の実包/空包が表示されます。
        <br />
        In tube shells内の実包/空包をクリックすると削除することが出来ます。
        <br />
        Resetボタンをクリックすることで、In tube
        shellsの中身を空にすることが出来ます。
        <br />
        Next Live Shellは、次弾が実包である確率です。
        <br />
        あくまでも確率です…
      </p>
      <p className="en">
        When you select the buttons for Live Shells and Empty Shells, the corresponding number of live shells/empty shells will be displayed in the In tube shells section.
        <br />
        You can click on the live shells/empty shells in the In tube shells section to remove them.
        <br />
        Clicking the Reset button will empty the contents of the In tube shells.
        <br />
        Next Live Shell represents the probability of the next shell being live. Remember,
        <br />
        it's just a probability...
      </p>
    </div>
  );
};

export default ManualSection;
